<template>
  <div class="maxDiv">
    <!-- nav -->
    <!-- <van-nav-bar :placeholder="true" :fixed="true" :title="$route?.name" /> -->
    <keep-alive>
      <router-view v-wechat-title="$route.name" v-if="$route.meta.needKeep" />
    </keep-alive>
    <!-- 没有needKeep标记的从没有缓存的出口区别 -->
    <router-view v-wechat-title="$route.name" v-if="!$route.meta.needKeep" />
    <!-- 底部导航 -->
    <tabbarCom ref="tabbarCom"></tabbarCom>
    <div class="tips">
      <van-dialog
        v-model="tipsShow"
        :show-confirm-button="false"
        style="width: 75vw"
        class="br-t12"
        v-if="tipsShow && tipsImage && tipsImage.length > 0"
        :confirmButtonText="
          tipsImage[tipsIndex]?.nextTxt ? tipsImage[tipsIndex]?.nextTxt : '确定'
        "
        :beforeClose="onBeforeClose"
        @confirm="() => {}"
      >
        <div class="flex jcc fdc">
          <img
            :src="tipsImage[tipsIndex].fileEo?.url"
            style="width: 75vw; max-height: 70vh"
            @click="onBeforeClose"
          />
          <van-radio-group v-model="isAddNoTips" class="bg-fff">
            <van-radio name="1" class="fz-12 c-fff" icon-size="16px"
              >以后不再提醒</van-radio
            >
          </van-radio-group>
          <van-button class="br-b12" @click="onBeforeClose">{{
            tipsImage[tipsIndex]?.nextTxt
              ? tipsImage[tipsIndex]?.nextTxt
              : "确定"
          }}</van-button>
          <div class="flex jcc pd-24 bg-0000 z999" @click="onBeforeClose">
            <van-icon name="close" color="#fff" size="20px" />
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import cookeUtil from "@/util/cookieUtil";
import rotationalApi from "@/api/rotational";
import vxUserApi from "@/api/vxUserApi";
import vueCookie from "vue-cookie";
import router from ".././router";
import wxUserBill from "@/api/wxUserBill";

import { mapState, mapActions } from "vuex";
export default {
  computed: mapState({
    ...mapState("app", ["list"]),
  }),
  data() {
    return {
      active: this.$route.query.active || 0,
      tipsIndex: 0,
      tipsShow: false,
      tipsImage: [],
      noTips: JSON.parse(localStorage.getItem("noTips")) || [],
      isAddNoTips: 0,
    };
  },
  created() {
    this.loginMethod();
  },
  updated() {
    this.$refs.tabbarCom.resetTabbarMethod();
  },
  mounted() {
    // let tipsShow = localStorage.getItem('tipsShow')
  },
  watch: {
    $route: {
      async handler(newVal) {
        if (!this.list) this.actionsList(await this.onLoad());
        this.list.forEach((item) => {
          if (
            item.entranceName == newVal.name &&
            !item.bol &&
            !this.noTips.includes(newVal.name + item.id)
          ) {
            this.tipsImage = [...this.tipsImage, item];
            this.tipsShow = true;
            item.bol = true;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    closeFn() {
      this.tipsShow = false;
      if (this.isAddNoTips) {
        this.noTips.push(this.$route.name + this.tipsImage[this.tipsIndex].id);
        localStorage.setItem("noTips", JSON.stringify(this.noTips));
      }
    },
    ...mapActions("app", ["actionsList"]),
    async onLoad() {
      const res = await rotationalApi.getGoodsRotationalList();
      return res.data.data;
    },
    async onBeforeClose(action, done) {
      console.log(action);
      if (!done) {
        if (this.isAddNoTips) {
          this.noTips.push(
            this.$route.name + this.tipsImage[this.tipsIndex].id
          );
          localStorage.setItem("noTips", JSON.stringify(this.noTips));
        }
        if (this.tipsIndex == this.tipsImage.length - 1) {
          this.tipsShow = false;
          if (
            this.tipsImage[this.tipsIndex].targetUrl &&
            this.tipsImage[this.tipsIndex].targetUrl != "undefined"
          )
            this.$router.push(this.tipsImage[this.tipsIndex].targetUrl);
        } else {
          this.tipsIndex++;
        }
        return;
      }
      if (action === "confirm") {
        if (this.tipsIndex == this.tipsImage.length - 1) {
          if (this.isAddNoTips) {
            this.noTips.push(this.$route.name);
            localStorage.setItem("noTips", JSON.stringify(this.noTips));
          }
          this.tipsShow = false;
          console.log(this.tipsShow);
          this.tipsImage[this.tipsIndex].bol = true;
          this.tipsIndex = 0;
          appApi.useSite(this.id);
          let currentUser;
          setTimeout(async () => {
            currentUser = await vxUserApi.getCurrentUser();
            if (currentUser.data === undefined) {
              return;
            }
            localStorage.setItem("user", JSON.stringify(currentUser.data.data));
            // this.$store.dispatch('setUserInfos', currentUser.data.data)
          }, 1000);
          if (this.tipsImage[this.tipsIndex].nextPath)
            this.$router.push(this.tipsImage[this.tipsIndex].nextPath);

          return done(false); //直接return它即可阻止
        }
        // if()...也可以加入一些条件
        this.tipsIndex++;
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    async initMethod() {
      //获取当前登录用户
      const { data: currentUser } = await vxUserApi.getCurrentUser();
      if (currentUser === undefined) {
        return;
      }
      this.$data.currentUser = currentUser.data;
      localStorage.setItem("user", JSON.stringify(currentUser.data));
      //积分处理
      const res = await wxUserBill.getCurrentUserIntegral();
      this.num = res?.data.data.num;
      localStorage.setItem("userIntegral", res?.data.data.num);
      try {
        this.$store.dispatch("setUserIntegral", res?.data.data.num);
        this.$store.dispatch("setUserIntegralObj", res?.data.data);
      } catch (error) {}
    },
    //获取code并登录
    async loginMethod() {
      let parenOpenId = this.$route.query?.parenOpenId;
      // if (parenOpenId) {
      //   parenOpenId = parenOpenId.split('?')
      //   parenOpenId = parenOpenId[0]
      // }
      if (parenOpenId) {
        vueCookie.set("parenOpenId", parenOpenId);
      } else {
        parenOpenId = vueCookie.get("parenOpenId", parenOpenId);
      }
      const oldToken = cookeUtil.get(cookeUtil.AppTokenKey);
      const code = this.getCodeMethod();
      const { data: res } =
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "yizhe"
          ? await vxUserApi.login2(code, oldToken, parenOpenId)
          : await vxUserApi.login(code, oldToken, parenOpenId);
      if (res === undefined) {
        //登录失败，清除token
        cookeUtil.delete(cookeUtil.AppTokenKey);
        return;
      }
      let token = cookeUtil.get(cookeUtil.AppTokenKey);
      if (token == null) {
        router.go(0);
      }
      //登录成功 保存token
      cookeUtil.setDefaultTime(cookeUtil.AppTokenKey, res.data);
      this.initMethod();
    },

    //返回url中的code
    getCodeMethod() {
      var code = location.href;

      const codeIndex = code.indexOf("?code=");
      if (codeIndex === -1) {
        return null;
      }

      code = code.substring(codeIndex + "?code="?.length);

      code = code.substring(0, code.indexOf("&state=STATE"));
      return code;
    },
  },
};
</script>

<style lang="scss" scoped>
.maxDiv {
  height: 100vh;
  overflow: scroll;
  background-color: #f9f9f9;
}

::v-deep .van-checkbox__icon .van-icon {
  border-radius: 10px;
}

.tips ::v-deep .van-dialog {
  background-color: rgba($color: #000000, $alpha: 0);
}
</style>
