import Vue from "vue";
import navCom from "@/components/navCom.vue";
import zheButton from "@/components/zheButton.vue";
import swipeCom from "@/components/swipeCom.vue";
import tabbarCom from "@/components/tabbarCom.vue";
import tips from "@/components/tips.vue";
import szTips from "@/components/szTips.vue";
import dialogImg from "@/components/dialogImg.vue";
Vue.component('dialogImg', dialogImg)
Vue.component('szTips', szTips)
Vue.component('tips', tips)
Vue.component('tabbarCom', tabbarCom)
Vue.component('swipeCom', swipeCom)
Vue.component('navCom', navCom)
Vue.component('zheButton', zheButton)