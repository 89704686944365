<!--  -->
<template>
    <div class=''>
        <div class="tips fz-12 c-ccc bg-fff pd-5 pl-16">{{ text }}</div>
    </div>
</template>

<script>

export default {
    props: ['text'],
    components: {},
    data() {
        return {

        };
    },
}
</script>
<style lang='less' scoped></style>