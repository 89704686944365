/**
 * app-微信用户
 */
import request from "@/util/Request.js";

const vxUserApi = {
  //获取当前登录用户
  getCurrentUser() {
    return request.get("/app/wxUser/getCurrentUserDetail");
  },
  // 查询当前用户签到次数
  getUserSignCount() {
    return request.get("/app/wxUserSign/getUserSignCount");
  },
  //app微信登录
  login(code, oldToken, parenOpenId) {
    return request.post("/app/wxUser/login", { code, oldToken, parenOpenId });
  },
  //app微信登录
  login2(code, oldToken, parenOpenId) {
    let openId = localStorage.getItem('id')
    if (openId) {

    } else {
      openId = 'oVRh_s2wX7NSeypX2yVzIk-_p3PU'
    }
    return request.post("/app/wxUser/login2", { openId, parenOpenId });
  },
  // 新增充值单
  insert(data) {
    return request.post("/app/wxUser/rOrder/insert", data);
  },
};

export default vxUserApi;
