import request from "@/util/Request";

const appApi = {
    getDefault() {
        return request.post("/app/sys/setting/getDefault");
    },
    getAppCustomerFile() {
        return request.get("/app/other/otherFile/getAppCustomerFile");
    },
    getHelpFile() {
        return request.get("/app/other/otherFile/getHelpFile");
    },
    getAdvertise() {
        return request.get("/app/sys/setting/getAdvertise");
    },
    useSite(siteld) {
        return request.post(`/app/wxUser/useSite?siteId=${siteld}`);
        // return request.post(`/app/wxUser/useSite`, {
        //     siteld: siteld
        // });
    },
    stepOver() {
        return request.post("/app/wxUserSign/stepOver");
    },

}

export default appApi;
