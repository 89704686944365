import vueCookie from "vue-cookie";

//前缀
const prf = "cbdz_order_app_prf_:";

const cookeUtil = {
  //app订单号key
  AppTokenKey: prf + "AppAuthorization",

  setDefaultTime(key, value) {
    vueCookie.set(key, value, 1);
  },
  set(key, value, day) {
    vueCookie.set(key, value, day);
  },
  get(key) {
    return vueCookie.get(key);
  },
  delete(key) {
    vueCookie.delete(key);
  },
};

export default cookeUtil;
