<template>
  <div class="maxDiv">
    <keep-alive>
      <!-- 页面 -->
      <router-view v-if="$route.meta.needKeep" />
    </keep-alive>
    <!-- 没有needKeep标记的从没有缓存的出口区别 -->
    <router-view v-if="!$route.meta.needKeep" />
    <div class="tips">
      <van-dialog
        v-model="tipsShow"
        :show-confirm-button="false"
        style="width: 75vw"
        class="br-t12"
        v-if="tipsShow && tipsImage && tipsImage.length > 0"
        :confirmButtonText="
          tipsImage[tipsIndex]?.nextTxt ? tipsImage[tipsIndex]?.nextTxt : '确定'
        "
        :beforeClose="onBeforeClose"
        @confirm="() => {}"
      >
        <div class="flex jcc fdc">
          <img
            :src="tipsImage[tipsIndex].fileEo?.url"
            style="width: 75vw; max-height: 70vh"
            @click="onBeforeClose"
          />
          <van-radio-group v-model="isAddNoTips" class="bg-fff">
            <van-radio name="1" class="fz-12 c-fff" icon-size="16px"
              >以后不再提醒</van-radio
            >
          </van-radio-group>
          <van-button class="br-b12" @click="onBeforeClose">{{
            tipsImage[tipsIndex]?.nextTxt
              ? tipsImage[tipsIndex]?.nextTxt
              : "确定"
          }}</van-button>
          <div class="flex jcc pd-24 bg-0000 z999" @click="onBeforeClose">
            <van-icon name="close" color="#fff" size="20px" />
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import appApi from "@/api/appApi";
import vxUserApi from "@/api/vxUserApi";
import rotationalApi from "@/api/rotational";
import { mapState, mapActions } from "vuex";

export default {
  computed: mapState({
    ...mapState("app", ["list"]),
  }),
  data() {
    return {
      active: this.$route.query.active || 0,
      tipsIndex: 0,
      tipsShow: false,
      tipsImage: [],
      noTips: JSON.parse(localStorage.getItem("noTips")) || [],
      isAddNoTips: false,
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    closeFn() {
      this.tipsShow = false;
      if (this.isAddNoTips) {
        this.noTips.push(this.$route.name + this.tipsImage[this.tipsIndex].id);
        localStorage.setItem("noTips", JSON.stringify(this.noTips));
      }
    },
    ...mapActions("app", ["actionsList"]),
    async onLoad() {
      const res = await rotationalApi.getGoodsRotationalList();
      return res.data.data;
    },
    async onBeforeClose(action, done) {
      if (!done) {
        if (this.isAddNoTips) {
          this.noTips.push(
            this.$route.name + this.tipsImage[this.tipsIndex].id
          );
          localStorage.setItem("noTips", JSON.stringify(this.noTips));
        }
        if (
          this.tipsImage[this.tipsIndex].targetUrl &&
          this.tipsImage[this.tipsIndex].targetUrl != "undefined" &&
          this.tipsImage[this.tipsIndex].status
        )
          this.$router.push(this.tipsImage[this.tipsIndex].targetUrl);

        if (this.tipsIndex == this.tipsImage.length - 1) {
          this.tipsShow = false;
        } else {
          this.tipsIndex++;
        }
        return;
      }
      if (action === "confirm") {
        if (this.tipsIndex == this.tipsImage.length - 1) {
          if (this.isAddNoTips) {
            this.noTips.push(this.$route.name);
            localStorage.setItem("noTips", JSON.stringify(this.noTips));
          }
          this.tipsShow = false;
          console.log(this.tipsShow);
          this.tipsImage[this.tipsIndex].bol = true;
          this.tipsIndex = 0;
          appApi.useSite(this.id);
          let currentUser;
          setTimeout(async () => {
            currentUser = await vxUserApi.getCurrentUser();
            if (currentUser.data === undefined) {
              return;
            }
            localStorage.setItem("user", JSON.stringify(currentUser.data.data));
            // this.$store.dispatch('setUserInfos', currentUser.data.data)
          }, 1000);
          if (this.tipsImage[this.tipsIndex].nextPath)
            this.$router.push(this.tipsImage[this.tipsIndex].nextPath);

          return done(false); //直接return它即可阻止
        }
        // if()...也可以加入一些条件
        this.tipsIndex++;
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
  },
  watch: {
    $route: {
      async handler(newVal) {
        if (!this.list) this.actionsList(await this.onLoad());
        console.log(this.list, "list");
        this.list.forEach((item) => {
          console.log(
            item.entranceName == newVal.name,
            !item.bol,
            this.noTips.includes(newVal.name),
            "名称"
          );
          // !item.bol &&
          if (
            item.entranceName == newVal.name &&
            !this.noTips.includes(newVal.name + item.id)
          ) {
            this.tipsImage = [...this.tipsImage, item];
            console.log(this.tipsImage);
            this.tipsShow = true;
            item.bol = true;
            // this.id = item.id
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.maxDiv {
  height: 100vh;
  overflow: scroll;
  background-color: #f4f5f7;
}

::v-deep .van-checkbox__icon .van-icon {
  border-radius: 10px;
}

.tips ::v-deep .van-dialog {
  background-color: rgba($color: #000000, $alpha: 0);
}
</style>
