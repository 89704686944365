import request from "@/util/Request";

const rotationalApi = {
    getGoodsRotationalListByType() {
        return request.get(`/app/other/rotational/getGoodsRotationalListByType/1`);
    },

    getGoodsRotationalList() {
        return request.get(`/app/other/entrance/getGoodsRotationalList`);
    },
}
export default rotationalApi