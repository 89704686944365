<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>



window.onload = function () {
  // 禁用双指放大
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
  // 禁用双击放大
  var lastTouchEnd = 0;
  document.documentElement.addEventListener(
    "touchend",
    function (event) {
      var now = Date.now();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    {
      passive: false,
    }
  );
};
</script>

<style lang="scss">
#app {
  max-width: 1040px;
  margin: auto;
}
</style>
