import { render, staticRenderFns } from "./dialogImg.vue?vue&type=template&id=7096eb3d&scoped=true&"
import script from "./dialogImg.vue?vue&type=script&lang=js&"
export * from "./dialogImg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7096eb3d",
  null
  
)

export default component.exports