import router from "@/router";
import axios from "axios";
import { Notify, Toast, Dialog } from "vant";
import cookeUtil from "@/util/cookieUtil";
import vueCookie from "vue-cookie";

const request = axios.create({
  // baseURL: 'http://192.168.1.5:9001/',
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: 'http://taosiyong.com:9001/', dev1
  // baseURL: 'http://139.155.36.85:9001/',
  // baseURL: 'http://47.242.38.187:9001/',
  // baseURL: 'http://localhost:9001/',
  // baseURL: 'http://175.178.131.58:9001/',d


  timeout: 60000,
});

//判断手机还是web
// function isPhone(){
//    //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
//    var info = navigator.userAgent;
//    //通过正则表达式的test方法判断是否包含“Mobile”字符串
//    var isPhone = /mobile/i.test(info);
//    //如果包含“Mobile”（是手机设备）则返回true
//    return isPhone
// }

//进行表单提交方式的url
const formDataPath = [
  "/app/orderSubsidy/uploadCommonFile",
  "/app/orderSubsidy/uploadAppendCommon",
  "/app/order/orderComment/insertOrderComment",
  '/app/order/refund/insertOrderRefund',
  '/app/wxUser/feedback/createFeedbackGroup',
  '/app/wxUser/feedback/answerFeedback',
  '/app/wxUser/todo/uploadTodo',
  '/app/order/addConfirmHandler',
  '/app/brush/order/addConfirmHandler',
  '/app/brush/order/comment/insert'
];
let req = [
  '/app/wxUser/getCurrentUserQrCode'
]
//不需要传token的url
const notTokenUrl = [];

//请求处理 
request.interceptors.request.use((value) => {
  let token = cookeUtil.get(cookeUtil.AppTokenKey)
  // 加载图标
  // Toast.loading({
  //   message: "加载中...",
  //   forbidClick: true,
  //   loadingType: "spinner",
  //   duration: "toast",
  // });

  value.headers.AppAuthorization = token;
  value.headers["Content-Type"] = "application/json";

  const currentRequestPath = value.url;
  //进行表单提交
  if (formDataPath.indexOf(currentRequestPath) > -1) {
    value.headers.setContentType("multipart/form-data");
  }
  if (req.indexOf(currentRequestPath) > -1) {
    value.responseType = "blob"
  }
  return value;
});
let bol = ''
//响应处理
request.interceptors.response.use(
  (success) => {
    const data = success.data;
    if (data.code !== "200" && success.data.type !== 'image/jpg') {
      //制空数据 与error同步
      if (bol == true) return success
      if (data.code === "403") {
        if403();
        return success;
      }

    }

    //清除加载框
    Toast.clear();
    return success;
  },
  (error) => {
    //提示信息
    const response = error.response;
    if (response.data.msg && response.data.msg.includes('已被禁用')) {
      //登录失败，清除token
      cookeUtil.delete(cookeUtil.AppTokenKey);
      bol = true
      Toast.clear();
      router.push('/app/enblue')
      return false
    }
    if (bol == true) return error
    if (response === undefined) {
      // Notify({ type: "danger", message: "网络错误,连接超时!" });
      Toast.clear();
      return error;
    } else {
      //单独处理403
      if (response.data.code === "403") {
        if403();
        return error;
      }
      if (response.data.code == '406') {
        Toast.clear();
        return Dialog({
          message: '请先绑定手机号',
          showCancelButton: true,
          confirmButtonText: '去绑定',
          cancelButtonText: '返回'
        }).then(() => {
          router.push('/app/my/userInfo')
        }).catch(() => {
          router.go(-1)
        })
      }

      //清除加载框
      Toast.clear();
      //提示后台返回信息
      if (response.data.msg != undefined && !response.data.msg.includes('参数') && !response.data.msg.includes('权限') && !response.data.msg.includes('异常')) {
        Notify({ type: "danger", message: response.data.msg, size: 'lg' });
      }
      return error;
    }
  }
);

//处理403
function if403() {

  const currentPath = router.currentRoute.path;
  //清除cookie
  cookeUtil.delete(cookeUtil.AppTokenKey);

  //重定向到微信授权页面
  // if (currentPath !== "/app/my") {
  //   router.replace("/app/my");
  // }
  //清除加载框
  Toast.clear();
  // Notify({ type: "danger", message: "登录过期!" }); 
  // if (process.env.NODE_ENV === 'development') return
  let parenOpenId = router.history.current.query?.parenOpenId
  // Toast(router.history.current.query.parenOpenId, '6666')
  if (parenOpenId) {
    // parenOpenId = parenOpenId.split('?')
    // parenOpenId = parenOpenId[0]
    vueCookie.set('parenOpenId', parenOpenId);
  } else {
    parenOpenId = vueCookie.get('parenOpenId', parenOpenId);
  }
  // router.push('/404')
  setTimeout(() => {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    location.href =
      process.env.VUE_APP_BASE_NAME == 'souzhe' ?
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx41c6e314f921bc5f&redirect_uri=http%3A%2F%2Ftaosiyong.com/v1App&response_type=code&scope=snsapi_userinfo&state=STATE&parenOpenId=${parenOpenId}#wechat_redirect`
        :
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1a59ccd925b5d652&redirect_uri=http%3A%2F%2Fsou-zhe.com%2Fv1App&response_type=code&scope=snsapi_userinfo&state=STATE&parenOpenId=${parenOpenId}#wechat_redirect`;
  }, 500);
}

export default request;
