import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import '@/styles/index.scss'

//注册ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import Vconsole from "vconsole";
//注册vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
let vConsole
if (process.env.NODE_ENV === 'development') {
  vConsole = new Vconsole();
}
import '@/assets/iconfont/iconfont.css'
import '@/assets/albaba/iconfont.css'
export default vConsole;
import model from '../src/directive.js'

Object.keys(model).forEach(item => {
  Vue.directive(item, model[item])
})

import '@/util/component.js'

import { integralFn } from '@/util/util.js'
Vue.prototype.$integralFn = integralFn

//注册粘贴板1
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
import store from "./store";
Vue.config.productionTip = false;


import VueWechatTitle from 'vue-wechat-title'//动态修改title
Vue.use(VueWechatTitle)



new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
