<!--  -->
<template>
  <div class="">
    <van-tabbar v-model="active" @change="onChangeTabbarMethod" z-index="99">
      <van-tabbar-item icon="shop-o">
        <span>首页</span>
        <img
          slot="icon"
          slot-scope="props"
          src="@/assets/画板-全选中_slices/首页-搜索按钮点击@2x.png"
          v-if="props.active"
        />
        <img
          slot="icon"
          src="@/assets/画板-未选中_slices/首页-搜索按钮点击@2x.png"
          v-else
        />
      </van-tabbar-item>
      <van-tabbar-item icon="shop-o">
        0元专区
        <img
          slot="icon"
          slot-scope="props"
          src="@/assets/画板-全选中_slices/首页-搜索按钮点击@2x(4).png"
          v-if="props.active"
        />
        <img
          slot="icon"
          src="@/assets/画板-未选中_slices/首页-搜索按钮点击@2x(4).png"
          v-else
        />
      </van-tabbar-item>
      <van-tabbar-item icon="home-o"
        >赚积分
        <img
          slot="icon"
          slot-scope="props"
          src="@/assets/画板-全选中_slices/首页-搜索按钮点击@2x(2).png"
          v-if="props.active"
        />
        <img
          slot="icon"
          src="@/assets/画板-未选中_slices/首页-搜索按钮点击@2x(2).png"
          v-else
        />
      </van-tabbar-item>
      <van-tabbar-item icon="shop-o"
        >vip商品
        <img
          slot="icon"
          slot-scope="props"
          src="@/assets/画板-全选中_slices/首页-搜索按钮点击@2x(1).png"
          v-if="props.active"
        />
        <img
          slot="icon"
          src="@/assets/画板-未选中_slices/首页-搜索按钮点击@2x(1).png"
          v-else
        />
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o"
        >我的
        <img
          slot="icon"
          slot-scope="props"
          src="@/assets/画板-全选中_slices/首页-搜索按钮点击@2x(3).png"
          v-if="props.active"
        />
        <img
          slot="icon"
          src="@/assets/画板-未选中_slices/首页-搜索按钮点击@2x(3).png"
          v-else
        />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  props: {
    isLast: {
      default: true,
    },
  },
  components: {},
  data() {
    return {
      active: 0,
    };
  },
  created() {
    this.resetTabbarMethod();
  },
  methods: {
    //底部导航切换
    onChangeTabbarMethod(indexValue) {
      // this.$store.dispatch('todoFn')
      this.active = indexValue;
      const currentPath = this.$route.path;
      if (indexValue == 0) {
        this.$router.replace("/app/index");
      } else if (indexValue == 1) {
        this.$router.replace("/app/mallSd");
      } else if (indexValue == 2) {
        this.$router.replace("/app/mall");
      } else if (indexValue == 3) {
        this.$router.replace("/app/dataoke/index");
      } else {
        this.$router.replace("/app/my");
      }
    },
    //重新设置底部导航
    async resetTabbarMethod() {
      const currentPath = this.$route.path;
      if (currentPath.indexOf("/app/index") >= 0 && this.isLast) {
        this.$data.active = 0;
      } else if (currentPath.indexOf("/app/mallSd") >= 0 && this.isLast) {
        this.$data.active = 1;
      } else if (
        currentPath.indexOf("/app/dataoke/index") >= 0 &&
        this.isLast
      ) {
        this.$data.active = 3;
      } else if (currentPath.indexOf("/app/mall") >= 0 && this.isLast) {
        this.$data.active = 2;
      } else {
        this.$data.active = 4;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-tabbar {
  z-index: 99;
}

::v-deep .van-tabbar-item {
  color: #999 !important;
}
::v-deep .van-tabbar-item--active {
  color: #333 !important;
  font-weight: 600;
}
</style>
