<!--  -->
<template>
    <div class=''>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in rotationalList" :key="item.id">
                <a :href="item.targetUrl">
                    <van-image fit="cover" :src="item.fileEo.url" style="width: 100vw; height: 210px;"></van-image>
                </a>
            </van-swipe-item>
        </van-swipe>
    </div>
</template>

<script>
import rotationalApi from "@/api/rotational";

export default {
    components: {},
    data() {
        return {
            rotationalList: []

        };
    },
    created() {
        this.getRotationalList()
    },
    methods: {
        async getRotationalList() {
            const res = await rotationalApi.getGoodsRotationalListByType()
            this.rotationalList = res?.data?.data
        }
    }
}
</script>
<style lang='less' scoped></style>