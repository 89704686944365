import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import appMainLayout from "@/layout/mainLayout.vue";
import emptyLayout from "@/layout/emptyLayout.vue";

// app端页面
const appRoutes = [
  {
    path: "/",
    redirect: "/app/index",
  },
  {
    path: "*",
    component: () => import("@/view/NotFound.vue"),
  },
  {
    path: "/app",
    redirect: "/app/index",
  },
  // 成功页面
  {
    path: "/app/success",
    component: () => import("@/view/success.vue"),
  },
  {
    path: "/app/enblue",
    component: () => import("@/view/enblue.vue"),
  },
  {
    path: "/app/dataoke",
    component: emptyLayout,
    children: [
      // {
      //   path: 'index',
      //   name: "dataoke",
      //   component: () => import("@/view/dataoke/dataoke.vue"),
      // },

      {
        path: 'detile',
        name: "dataoke",
        component: () => import("@/view/dataoke/detile.vue"),
      },
      {
        path: 'serach',
        name: "搜索",
        component: () => import("@/view/dataoke/serach.vue"),
      },
      {
        path: 'dtkPost',
        name: "dataoke",
        component: () => import("@/view/dataoke/dtkPostOrder.vue"),
      },
    ]
  },
  // 主页
  {
    path: "/app/index",
    component: appMainLayout,
    children: [
      {
        path: "",
        name: "首页",
        component: () => import("@/view/mall/index.vue"),
        meta: {
          needKeep: true
        },
      },
      {
        name: "红包",
        path: "hongbao/:orderNo",
        component: () => import("@/view/index/hongBao.vue"),
      },
    ],
  },

  {
    path: "/app/malls",
    component: emptyLayout,
    children: [
      {
        path: '',
        name: "积分商城",
        component: () => import("@/view/mall/indexMall.vue"),
      },
    ]
  },
  {
    path: "/app/dataoke/index",
    component: appMainLayout,
    children: [
      {
        path: '',
        name: "VIP商品",
        component: () => import("@/view/dataoke/dataoke.vue"),
        meta: {
          // needKeep: true
        },
      },

    ]
  },
  {
    path: "/app/mallSd",
    component: appMainLayout,
    children: [
      {
        path: '',
        name: "0元专区",
        component: () => import("@/view/mall/indexSd.vue"),
      },
    ]
  },
  // 商城  //===
  {
    path: "/app/mall",
    component: appMainLayout,
    children: [
      {
        path: '',
        name: "赚积分",
        component: () => import("@/view/mall/indexMalls.vue"),
        meta: {
          // needKeep: true
        },
      },
    ]
  },
  {
    path: "/app/mall",
    component: emptyLayout,
    name: "商城",
    children: [
      {
        path: 'index',
        name: "补贴专区",
        component: () => import("@/view/index/index.vue"),
        meta: {
          needKeep: true
        },
      },
      {
        path: 'integral',
        name: "积分商城",
        component: () => import("@/view/mall/indexMall.vue"),
      },
      {
        path: 'mallss',
        name: "精品商城",
        component: () => import("@/view/mall/indexMalls.vue"),
      },
      {
        path: 'mallSd2',
        name: "精品商城",
        component: () => import("@/view/mall/indexSd2.vue"),
      },
      {
        path: 'mallDetile/:categoryId',
        name: "商城",
        component: () => import("@/view/mall/mallDetile.vue"),
      },
      {
        path: 'sd',
        name: "补贴",
        component: () => import("@/view/sd/procedure.vue"),
      },
      {
        path: 'postOrder',
        name: "提交订单",
        component: () => import("@/view/sd/postOrder.vue"),
      },
      {
        path: 'serach',
        name: "如何搜索",
        component: () => import("@/view/sd/serach.vue"),
      },
      {
        path: 'question',
        name: "常见问题",
        component: () => import("@/view/sd/question.vue"),
      },
      {
        path: 'postSuccess',
        name: "提交成功",
        component: () => import("@/view/sd/postSuccess.vue"),
      },
      {
        path: 'redEnvelope',
        name: "领取红包",
        component: () => import("@/view/sd/redEnvelope.vue"),
      },
      {
        path: 'goodsEvaluate/:goodsId',
        name: "商品评价",
        component: () => import("@/view/mall/goodsEvaluate.vue"),
      },
      {
        path: 'oderList',
        name: "订单列表",
        component: () => import("@/view/mall/oderList.vue"),
      },
      {
        path: 'oderDetile/:order',
        name: "订单详情",
        component: () => import("@/view/mall/oderDetile.vue"),
      },
      {
        path: 'logisticsDetile',
        name: "物流详情",
        component: () => import("@/view/mall/logisticsDetile.vue"),
      },
    ]
  },
  // 我的
  {
    path: "/app/my/",
    component: appMainLayout,
    children: [
      {
        path: "",
        name: "我的",
        component: () => import("@/view/my/my.vue"),
        meta: {
          needKeep: true
        },
      },
    ],
  },
  {
    path: "/app/goods",
    component: appMainLayout,
    children: [
      {
        path: '',
        name: "补贴商品",
        component: () => import("@/view/sd/index.vue"),
      },
    ]
  },
  {
    path: "/app/sdList",
    component: emptyLayout,
    children: [
      {
        path: '',
        name: "补贴列表",
        component: () => import("@/view/sd/sdList.vue"),
      },
    ]
  },
  {
    path: "/app/goods",
    component: emptyLayout,
    children: [
      {
        path: 'sdDetile',
        name: "补贴商品详情",
        component: () => import("@/view/sd/sdDetile.vue"),
      },
      {
        path: 'orderList',
        name: "补贴列表",
        component: () => import("@/view/sd/orderList.vue"),
      },
      {
        path: 'orderDetile',
        name: "补贴详情",
        component: () => import("@/view/sd/orderDetile.vue"),
      },

    ]
  },
  {
    path: "/app/my",
    component: emptyLayout,
    name: "我的",
    children: [
      {
        path: "feedbackDetile",
        name: "订单搜索",
        component: () => import("@/view/my/feedback/detile.vue"),
      },
      {
        path: "newHead",
        name: "新手教程",
        component: () => import("@/view/my/newHead.vue"),
      },
      {
        path: "userAgreement",
        name: "新手教程",
        component: () => import("@/view/my/userAgreement.vue"),
      },
      {
        path: "feedback",
        name: "反馈",
        component: () => import("@/view/my/feedback/index.vue"),
      },
      {
        path: "about",
        name: "关于我们",
        component: () => import("@/view/my/about.vue"),
      },
      {
        path: "help",
        name: "关于我们",
        component: () => import("@/view/my/help.vue"),
      },
      {
        path: "task",
        name: "任务列表",
        component: () => import("@/view/my/task.vue"),
      },
      {
        path: 'openVip',
        name: "开启补贴会员",
        component: () => import("@/view/my/openVip.vue"),
      },
      {
        path: "list",
        name: "订单搜索",
        component: () => import("@/view/my/feedback/list.vue"),
      },
      {
        path: "userInfo",
        name: "个人信息",
        component: () => import("@/view/my/userInfo.vue"),
      },
      {
        path: "question",
        name: "代办与违规",
        component: () => import("@/view/my/question/index.vue"),
      },
      {
        path: "questionDetile",
        name: "代办与违规详情",
        component: () => import("@/view/my/question/questionDetile.vue"),
      },
      {
        path: "orderSouch",
        name: "订单搜索",
        component: () => import("@/view/my/orderSouch.vue"),
        meta: {
          needKeep: true
        }
      },
      {
        path: "test",
        name: "测试",
        component: () => import("@/view/my/test.vue"),
      },
      {
        path: "evaluate/:orderNo",
        name: "我的评价",
        component: () => import("@/view/my/evaluate.vue"),
      },
      {
        path: "orderDetail/:orderNo",
        name: "订单详情",
        component: () => import("@/view/my/orderDetail.vue"),
      },
      {
        path: "afterService",
        name: "选择售后",
        component: () => import("@/view/my/afterService.vue"),
      },
      {
        path: "submitPage/:orderNo",
        name: "提交售后",
        component: () => import("@/view/my/submitPage.vue"),
      },
      {
        path: "refundDetile",
        name: "售后详情",
        component: () => import("@/view/my/refundDetile.vue"),
      },
      {
        path: "addres",
        name: "我的收货地址",
        component: () => import("@/view/my/addres.vue"),
      },
      {
        path: "editAddres",
        name: "编辑收货地址",
        component: () => import("@/view/my/editAddres.vue"),
      },
      {
        path: "signIn",
        name: "签到",
        component: () => import("@/view/my/signIn.vue"),
      },
      {
        path: "wxUserBill",
        name: "我的收入",
        component: () => import("@/view/my/wxUserBill.vue"),
      },
      {
        path: "balance",
        name: "我的钱包",
        component: () => import("@/view/my/balance.vue"),
      },
      {
        path: "Integral",
        name: "积分明细",
        component: () => import("@/view/my/Integral.vue"),
      },
      {
        path: "myIntegral",
        name: "积分列表",
        component: () => import("@/view/my/myIntegral.vue"),
      },
      {
        path: "myintegraldetails",
        name: "积分列表",
        component: () => import("@/view/my/myintegraldetails.vue"),
      },
      {
        path: "userChildUser",
        name: "积分明细",
        component: () => import("@/view/my/userChildUser.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes: appRoutes,
});


// router.beforeEach((to, from, next) => {
//   document.title = to.name
// })

export default router;



// arr1 = [
//   { id: 2259, s1: '1', s2: '3', price: 100, stock_num: 9999 },
//   { id: 2260, s1: '2', s2: '4', price: 100, stock_num: 9999 },
//   { id: 2260, s1: '1', s2: '4', price: 100, stock_num: 9999 },
//   { id: 2260, s1: '1', s2: '3', price: 100, stock_num: 9999 }
// ],

//   [{ "specName": "黑色,小的", "price": 61, },
//   { "specName": "红色,大的", "price": 121, },
//   { "specName": "红色,小的", "price": 61, },
//   { "specName": "黑色,大的", "price": 121, }
//   ]