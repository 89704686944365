// aaa模块
export default {
    namespaced: true,
    state: {
        list: ''
    },
    // 下面的是默认不带命名空间的
    mutations: {
        setList(state, value) {
            console.log("actionsList", value);
            state.list = value
            console.log(state.list, 'list');
        }
        // 调用mutations:this.$store.commit('模块名/方法名',实参)
    },
    actions: {
        actionsList(store, state) {
            // store调用当前模块的mutations方法不需要模块名,访问任意当前模块的东西不需要模块名，只是外部调用需要
            console.log("actionsList", state);
            store.commit("setList", state)
        }
    },

}