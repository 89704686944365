export function integralFn(integral, type, integralFn) {
    return type == 4 ? '送' + integral + '积分' : type == 1 ? '送' + integral + '积分' : type == 2 ? '消耗' + integral + '积分' : integral + '积分'
}
let wxUserBillStatusObj = {
    run: '进行中',
    fail: '转账失败',
    exception: '提现失败，系统将在6小时内回退余额中',
    success: '转账成功',
}
export function wxUserBillStatus(status) {
    return `(${wxUserBillStatusObj[status]})`
}
