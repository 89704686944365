<template>
  <div>
    <!-- 作为fixed之后的填充 -->
    <van-nav-bar />
    <van-nav-bar :title="$route.name" left-arrow :right-text="rigthtText" fixed @click-right="rightClick"
      @click-left="returnMethod()" />
  </div>
</template>

<script>
export default {
  props: ["title", 'rigthtText', 'url'],
  methods: {
    //返回
    returnMethod() {
      if (this.url) {
        this.$router.push(this.url)
        return false
      }
      this.$router.go(-1)
    },
    rightClick() {
      this.$emit("rightClick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
