import Vue from 'vue'
import Vuex from 'vuex'
import wxUserBill from "@/api/wxUserBill";
import router from "@/router";

Vue.use(Vuex)
import app from './modules/app.js'
export default new Vuex.Store({
    state: {
        userInfo: JSON.parse(localStorage.getItem('user')) || '',
        userIntegral: localStorage.getItem('userIntegral') || 0,
        userIntegralObj: {},
        openList: [
            'oVRh_s2wX7NSeypX2yVzIk-_p3PU'
        ],
    },
    getters: {
        isBuff(state) {
            let bol = false
            if (state.userInfo.buffList && state.userInfo.buffList.length > 0) {
                state.userInfo?.buffList.forEach(item => {
                    if (item.buffType == 'brush_vip') {
                        bol = true
                    }
                })
            }
            return bol
        }
    },
    mutations: {
        setUserInfos(state, userInfo) {
            console.log(userInfo, 'userInfo');
            state.userInfo = userInfo
        },
        setUserIntegral(state, userIntegral) {
            console.log(userIntegral, 'userIntegral');
            state.userIntegral = userIntegral
        },
        setUserIntegralObj(state, userIntegralObj) {
            console.log(userIntegralObj, 'userIntegralObj');
            state.userIntegralObj = userIntegralObj
        },
    },
    actions: {
        setUserInfos(store, value) {
            console.log(value, 'userInfo');
            store.commit('setUserInfo', value)
        },
        setUserIntegral(store, value) {
            console.log(value, "value");
            store.commit('setUserIntegral', value)
        },
        setUserIntegralObj(store, value) {
            console.log(value, "value2");
            store.commit('setUserIntegralObj', value)
        },
    },

    modules: {
        app
    }
})