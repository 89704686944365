<!--  -->
<template>
    <div style="padding:  16px;" class="button">
        <van-button round block type="danger" @click="submit">{{ text }}</van-button>
    </div>
</template>

<script>

export default {
    props: ['text'],
    components: {},
    data() {
        return {

        };
    },
    methods: {
        submit() {
            this.$emit('submit')
        }
    }
}
</script>
<style lang='scss' scoped>
.button {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 50px;
}
</style>