/**
 * app-微信用户
 */
import request from "@/util/Request.js";

const wxUserBill = {
    getCountByPhone(phone) {
        return request.get("/app/wxUser/getCountByPhone", {
            params: {
                phone: phone
            }
        });
    },
    sendSmsCode(params) {
        return request.post("/app/wxUser/sendSmsCode", params);
    },

    getCurrentUserQrCode(openId) {

        return request({
            url: "/app/wxUser/getCurrentUserQrCode",
            method: "get",
            params: {
                targetUrl: `${process.env.VUE_APP_BASE_API}v1App/#/app/my/?parenOpenId=${openId}`
            },
        })
    },
    getALLReturnAmountByStatus() {
        return request.get("/app/brush/order/getAllReturnAmountByStatus", {
            params: {
                status: 'buying'
            }
        });
    },
    currentBindMobile(params) {
        return request.post("/app/wxUser/currentBindMobile", params);
    },
    getStatusCount() {
        return request.get("/app/wxUser/todo/getStatusCount");
    },
    getCurrentUserChildUser() {
        return request.get("/app/wxUser/getCurrentUserChildUser");
    },
    getUserAllCollectionAmount() {
        return request.get("/app/wxUserBill/getUserAllCollectionAmount");
    },
    getCurrenUserUnreadNum() {
        return request.get("/app/wxUser/feedback/getCurrenUserUnreadNum");
    },
    getCurrentUserIntegral() {
        return request.get("/app/wxUser/integral/getCurrentUserIntegral");
    },
    getCurrentUserBalancePage(pageNum, pageSize) {
        return request.get("/app/wxUser/balance/getCurrentUserBalancePage", { params: { pageNum, pageSize } });
    },
    transferWx(transferAmount) {
        return request.post("/app/wxUser/balance/transferWx",
            transferAmount
        );
    },
    getCurrentUserBalance() {
        return request.get("/app/wxUser/balance/getCurrentUserBalance"
        );
    },
    getCurrentUserOrderCount() {
        return request.get("/app/order/getCurrentUserOrderCount");
    },
    getBrushOrderStatusCount() {
        return request.get("/app/brush/order/getBrushOrderStatusCount");
    },
    getUserBillPage(pageNum, pageSize) {
        return request.get("/app/wxUserBill/getUserBillPage", { params: { pageNum, pageSize } });
    },
    getCurrentUserIntegralPage(pageNum, pageSize) {
        return request.get("/app/wxUser/integral/getCurrentUserIntegralPage", { params: { pageNum, pageSize } });
    },
    currentBindMobileAndTbNo(params) {
        return request.post("/app/wxUser/currentBindMobileAndTbNo", params);
    },
};

export default wxUserBill;
