<!--  -->
<template>
    <div class=''>
        <transition name="van-fade">
            <div class="tips flex aic jcsb br-10 pd-5" v-if="tipsShow">
                <div class="c-fff fz-14">
                    你的积分暂时不足
                </div>
                <van-button color="#e87c4d" round size="mini" @click.stop="goIntegralFn">去赚积分</van-button>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
            tipsShow: false,
        };
    },
    methods: {
        tipsFn() {
            this.tipsShow = true
            setTimeout(() => {
                this.tipsShow = false
            }, 5000);
        },
        goIntegralFn() {
            this.$emit('goIntegralFn')
        }
    }
}
</script>
<style lang='scss' scoped>
.tips {
    width: 80%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, .5);
}
</style>